import React, { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Popup } from 'semantic-ui-react';
import { useIsMutating } from 'react-query';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import styles from './ViewGuests.module.scss';
import { showQuickView } from '../../../util/modalFlows';
import { showModal, modalBack } from 'legacy/actions/modalActions';
import { removeRefundedGuest } from 'legacy/actions/eventActions';
import Spinner from '../../util/Spinner/Spinner';
import { useEventGuestListSearch } from 'legacy/search/hooks';
import _flatten from 'lodash/flatten';
import classNames from 'classnames';

import ModalBase from '../ModalBase/ModalBase';
import { ModalInput } from '../ModalUtils/index';
import {
  useRefundTicketMutation,
  useGetEventSummary,
  useRefundMultiTicketCommand
} from 'legacy/events/hooks';
import * as toast from 'legacy/common/utils/toast';
import { FaQrcode, FaTicketAlt } from 'react-icons/fa';
import { useFriendRequestCommandsForGuestList } from 'legacy/user/hooks';
import DropdownComponent from 'legacy/common/components/dropdown/Dropdown';
import { hasPurchasedAdditionalTickets } from 'legacy/tickets/helpers';

const guestMenu = (
  person,
  selfId,
  router,
  showModal,
  modalBack,
  refundUserTicket
) => (
  <Menu>
    <Menu.Item
      key="1"
      onClick={() => showQuickView(person._id, selfId, router)}
    >
      View Profile
    </Menu.Item>
    <Menu.Item
      key="2"
      id={`refund-ticket-${person._id}`}
      onClick={() => {
        if (!person.isPurchaser) {
          showModal('ALERT', {
            message: `This user's ticket was purchased by ${person?.purchaser?.name}. To refund their ticket, you will need to refund the original purchaser's transaction.`,
            onSuccess: modalBack
          });
          return;
        }

        const message =
          person.numberOfTickets > 1
            ? `Are you sure you want to submit a refund for ${person.name}? This user purchased ${person.numberOfTickets} tickets and all will be refunded.`
            : `Are you sure you want to submit a refund for ${person.name}? They will lose access to their ticket.`;

        showModal('CONFIRMATION', {
          action: () => refundUserTicket(person),
          onCancel: modalBack,
          showLoading: true,
          title: 'Refund ticket',
          disableCloseOnCancel: true,
          actionText: 'Refund Ticket',
          destructive: true,
          message
        });
      }}
    >
      Submit a Refund
    </Menu.Item>
  </Menu>
);

const renderTicketInfoForGuests = (person) => {
  if (!person?.transaction) {
    return null;
  }

  const { numberOfTickets, isPurchaser } = person.transaction;

  return hasPurchasedAdditionalTickets({
    isPurchaser,
    numberOfTickets
  }) ? (
    <div className="flex text-gray-400 text-xs mt-1">
      <FaTicketAlt className="mr-2 text-sm" />
      {`${numberOfTickets} Tickets`}
    </div>
  ) : null;
};

const renderTicketInfoForOrganizer = (person, { onPurchaserClick }) => {
  if (person?.status === 'failed') {
    return (
      <span className={styles.error}>
        {'Payment failed '}
        <Popup
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          position="right center"
          trigger={<i className="fa fa-info-circle" />}
          size="tiny"
          content="This user has been notified of the failed payment and will be able to retry. They will not receive an event ticket until the payment succeeds."
        />
      </span>
    );
  }

  if (!person?.isPurchaser) {
    return (
      <div className="text-gray-500 text-xs mt-1" data-testid="bought-by">
        bought by
        <span
          className="ml-1 text-primary-500 cursor-pointer"
          onClick={onPurchaserClick}
        >
          {person?.purchaser?.name}
        </span>
      </div>
    );
  }

  if (person?.isPurchaser && person.numberOfTickets > 1) {
    const claimedTickets =
      person.numberOfTickets - person.numberOfUnclaimedTickets;

    return (
      <div className="flex text-gray-400 text-xs mt-1">
        <FaTicketAlt className="mr-2 text-sm" />
        {`${claimedTickets}/${person.numberOfTickets} `}
        Tickets claimed
      </div>
    );
  }

  return (
    <div className="flex text-gray-400 text-xs mt-1">
      <FaTicketAlt className="mr-2 text-sm" />
      {`${person?.numberOfTickets} `}
      {person?.numberOfTickets > 1 ? 'Tickets' : 'Ticket'}
    </div>
  );
};

const renderTicketInfo = (person, { isOrganizer, onPurchaserClick }) => {
  if (isOrganizer) {
    return renderTicketInfoForOrganizer(person, { onPurchaserClick });
  }

  return renderTicketInfoForGuests(person);
};

const renderFriendStatus = ({ person, friendRequestCommands }) => {
  const {
    sendFriendRequestCommand,
    cancelFriendRequestCommand,
    acceptFriendRequestCommand,
    declineFriendRequestCommand,
    removeFriendCommand
  } = friendRequestCommands;

  const friendStatus = person.friendStatus;

  if (friendStatus === 'friends' || friendStatus === 'fbFriends') {
    return (
      <DropdownComponent
        label="Friends"
        items={[
          {
            label: <span className="text-red-500">Unfriend</span>,
            onClick: () => {
              removeFriendCommand.mutate(person._id);
            }
          }
        ]}
      />
    );
  }

  if (friendStatus === 'requestable') {
    return (
      <div
        className="w-[76px] py-1 text-center rounded border-1 bg-primary-400 cursor-pointer"
        onClick={() => {
          sendFriendRequestCommand.mutate(person._id);
        }}
        data-testid={`button-send-friend-request-${person._id}`}
      >
        <div className="text-xs text-white">Add Friend</div>
      </div>
    );
  }

  if (friendStatus === 'requestSent') {
    return (
      <div
        className="w-[76px] py-1 text-center rounded border-1 border-gray-600 cursor-pointer"
        data-testid={`button-requested-${person._id}`}
        onClick={() => {
          cancelFriendRequestCommand.mutate(person._id);
        }}
      >
        <div className="text-xs text-gray-600">Requested</div>
      </div>
    );
  }

  if (friendStatus === 'requestReceived') {
    return (
      <DropdownComponent
        label="Respond"
        items={[
          {
            label: <span className="text-primary-400">Accept</span>,
            onClick: () => {
              acceptFriendRequestCommand.mutate(person._id);
            }
          },
          {
            label: <span className="text-red-500">Decline</span>,
            onClick: () => {
              declineFriendRequestCommand.mutate(person._id);
            }
          }
        ]}
      />
    );
  }

  if (friendStatus === 'requestReceived') {
    return (
      <div className="flex">
        <div
          className="mr-2 px-2 py-1 rounded border-1 border-gray-600 cursor-pointer"
          data-testid={`button-accept-${person._id}`}
          onClick={() => {
            acceptFriendRequestCommand.mutate(person._id);
          }}
        >
          <div className="text-xs text-primary-600">Accept</div>
        </div>
        <div
          className="px-2 py-1 rounded border-1 border-gray-600 cursor-pointer"
          data-testid={`button-decline-${person._id}`}
          onClick={() => {
            declineFriendRequestCommand.mutate(person._id);
          }}
        >
          <div className="text-xs text-red-500">Decline</div>
        </div>
      </div>
    );
  }

  return null;
};

const Person = ({
  person,
  isOrganizer,
  showModal,
  modalBack,
  refundUserTicket,
  friendRequestCommands,
  ...props
}) => {
  const isComplimentary =
    !person.friendStatus && !person.firstName && !person.lastName;

  const onGuestClick = () => {
    if (!isComplimentary) {
      showQuickView(person._id, props.self._id, props.router);
    }
  };

  return (
    <div
      className={classNames(
        styles.person,
        isComplimentary && styles.complimentaryGuest
      )}
      key={person._id}
      {...props}
    >
      <div className="details">
        <div
          className={`pic ${classNames(!isComplimentary && 'cursor-pointer')}`}
          onClick={onGuestClick}
        >
          {person.photo ? (
            <img src={person.photo.url} alt="" />
          ) : (
            <i className="fas fa-user-alt" />
          )}
        </div>
        <div className={styles.guestInfo}>
          <div
            className={`text-sm text-gray-800 ${classNames(
              !isComplimentary && 'cursor-pointer'
            )}`}
            onClick={onGuestClick}
          >
            <div className="truncate">
              {person.name || `${person.firstName} ${person.lastName}`}
            </div>
          </div>
          <div className={styles.amount}>
            {renderTicketInfo(person, {
              isOrganizer,
              onPurchaserClick: () => {
                showQuickView(
                  person.purchaser.id,
                  props.self._id,
                  props.router
                );
              }
            })}
          </div>
        </div>
      </div>

      <div className="flex-shrink-0">
        {!isComplimentary &&
          renderFriendStatus({
            person,
            friendRequestCommands
          })}
      </div>

      {!isComplimentary && isOrganizer && (
        <Dropdown
          className="cursor-pointer py-2 px-2 rounded-full"
          trigger={['click']}
          overlay={guestMenu(
            person,
            props.self._id,
            props.router,
            showModal,
            modalBack,
            refundUserTicket
          )}
        >
          <i className="fa fa-ellipsis-v" data-testid={`${person._id}-menu`} />
        </Dropdown>
      )}
    </div>
  );
};

const ViewGuests = ({
  baseModalProps,
  event,
  self,
  removeRefundedGuest,
  showModal,
  modalBack
}) => {
  const router = useRouter();
  const isOrganizer = event && event.isOrganizer;
  const {
    query: { slug: eventId }
  } = router;

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 200);

  const friendRequestCommands = useFriendRequestCommandsForGuestList({
    eventId: event._id,
    searchTerm: debouncedSearch,
    isOrganizer
  });

  const {
    data: guests,
    isLoading: isLoadingGuestList,
    isFetchingNextPage,
    fetchNextPage,
    remove: removeGuestList
  } = useEventGuestListSearch(isOrganizer, eventId, debouncedSearch);

  const { data: eventSummary } = useGetEventSummary(eventId, isOrganizer);

  const allGuests = isLoadingGuestList ? [] : _flatten(guests?.pages);
  const scannedGuests = eventSummary?.totals?.scannedGuests || 0;
  const unclaimedTickets = eventSummary?.totals?.unclaimedTickets || 0;

  const { mutate: refundSingleTicket } = useRefundTicketMutation();
  const isLoadingRefundTicket = useIsMutating(['/refundTicket']);

  const { mutate: refundMultiTickets } = useRefundMultiTicketCommand();

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const refundUserTicket = async ({ event, person }) => {
    const options = {
      onSuccess: () => {
        removeGuestList();
        removeRefundedGuest(event._id, person._id);
        toast.success('A ticket refund for the user has been submitted.');
        modalBack();
      },
      onError: (err) =>
        showModal('ALERT', {
          message: err.message,
          hideBackButton: true,
          close: modalBack
        })
    };

    if (person?.numberOfTickets > 1) {
      refundMultiTickets({ eventId: event._id, userId: person._id }, options);
      return;
    }

    refundSingleTicket({ eventId: event._id, userId: person._id }, options);
  };

  // TODO: Extract this somewhere else?
  // Allows fetching the next page of results after scrolling to the bottom of the modal
  // https://medium.com/fredwong-it/react-detect-scroll-to-bottom-event-7c18350c4ef6
  const handleScroll = ({ target }) => {
    if (isLoadingGuestList || isFetchingNextPage) {
      return;
    }

    const currentPageNumber = guests.pages.length - 1;
    const currentPage = guests.pages[currentPageNumber];
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      currentPage.length !== 0
    ) {
      fetchNextPage({ pageParam: currentPageNumber + 1 });
    }
  };

  const _renderGuests = (guests) =>
    guests.map((guest) => {
      const person = isOrganizer ? { ...guest, ...guest.user } : guest;
      return (
        <Person
          key={person._id}
          person={person}
          isOrganizer={isOrganizer}
          router={router}
          self={self}
          showModal={showModal}
          modalBack={modalBack}
          refundUserTicket={() =>
            refundUserTicket({
              event,
              person
            })
          }
          friendRequestCommands={friendRequestCommands}
        />
      );
    });

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={styles.wrapper}>
        <h2 className="modalTitle">Guest List</h2>
        <div className="search">
          <div className="icon">
            <i className="fas fa-search" />
          </div>
          <ModalInput
            name="search"
            value={search}
            onChange={handleChange}
            placeholder="Filter by name..."
            data-testid="search-guest-list"
          />
        </div>
        <div
          className={styles.guestList}
          onScroll={handleScroll}
          data-cy="guest-list"
        >
          {isLoadingGuestList || isLoadingRefundTicket ? (
            <div className={styles.loading}>
              <Spinner
                width={60}
                height={60}
                data-testid="view-guest-list-spinner"
              />
            </div>
          ) : (
            <>
              {allGuests.length === 0 ? (
                <div className={styles.noSearchResults}>No Search Results</div>
              ) : (
                _renderGuests(allGuests)
              )}
              {isFetchingNextPage && (
                <div className={styles.loading}>
                  <Spinner width={20} height={20} />
                </div>
              )}
            </>
          )}
        </div>

        {isOrganizer ? (
          <div className="w-full flex text-gray-400 justify-between">
            <div>
              <div className="text-sm ">Guests Scanned</div>
              <div className="flex items-center mt-1">
                <FaQrcode className="text-base mr-2" />
                <div
                  className="text-2xl text-gray-500"
                  data-testid="scanned-guests"
                >
                  {scannedGuests}
                </div>
              </div>
            </div>

            <div>
              <div className="text-sm ">Unclaimed Tickets</div>
              <div className="flex items-center mt-1">
                <FaTicketAlt className="text-base mr-2" />
                <div
                  className="text-2xl text-gray-500"
                  data-testid="unclaimed-tickets"
                >
                  {unclaimedTickets}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  self: state.user.user
});

const actions = {
  modalBack,
  removeRefundedGuest,
  showModal
};

export default connect(mapStateToProps, actions)(ViewGuests);
