const publicRoutes = [
  '/join',
  '/contact',
  '/about',
  '/',
  '/features',
  '/pricing',
  '/login',
  '/register',
  '/privacy',
  '/terms',
  '/tickets/claim/[shareToken]',
  '/events/[slug]',
  '/resetPassword',
  '/organizations/[organizationId]',
  '/associations/[associationId]'
];

const authRoutes = ['/login', '/register'];
const privateAuthRoutes = ['/login/updateProfile', '/login/verifyEmail'];

export const isPublicRoute = (pathname: string): boolean =>
  publicRoutes.includes(`/${pathname.split('/')[1]}`) ||
  publicRoutes.includes(pathname);

export const isAuthRoute = (pathname: string): boolean =>
  authRoutes.includes(`/${pathname.split('/')[1]}`);

export const isPrivateAuthRoute = (pathname: string): boolean =>
  privateAuthRoutes.includes(pathname);

export const isEventPageRoute = (pathname: string): boolean =>
  pathname === '/events/[slug]';
export const isClaimTicketPageRoute = (pathname: string): boolean =>
  pathname === '/tickets/claim/[shareToken]';
