import {
  MigrationRequirementsDto,
  EditSuggestions,
  EditUser,
  OnboardingRequirements,
  UserMe,
  CanTextAllDto,
  HostingRequirementsLinkResponse,
  HostingRequirementsLinkParams
} from './types';
import { VerificationSessionDto } from '../auth/types';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { types } from 'legacy/actions/userActions';
import { ErrorResponse } from 'legacy/common/utils/error';

import {
  EventLean,
  RequiredEventRatingsDto,
  SuggestedFriendsFromEventDto
} from 'legacy/events/types';

import {
  acceptFriendRequest,
  cancelFriendRequest,
  confirmEditPhoneVerificationSession,
  createEditPhoneVerificationSession,
  declineFriendRequest,
  editMe,
  getAuthMigrationRequirements,
  getCanTextAll,
  getEditSuggestions,
  getHostedEvents,
  getHostingRequirementsLink,
  getMe,
  getRequiredEventRatings,
  postOnboardingRequirements,
  removeFriend,
  sendFriendRequest,
  updateEmail,
  uploadGalleryPhoto,
  uploadProfilePicture
} from './service';
import { GenericSuccessResponse } from 'legacy/common/types/response';
import { UserAllPhoto } from 'legacy/common/types/models';

import { queryClient } from 'legacy/common/utils/react-query';
import useAuthenticationStatus from 'legacy/common/hooks/useAuthenticationStatus';

/** Convenience method to always disable a query for unauthenticated users, and otherwise to use the options value. */
const getEnabledStatus = ({
  options,
  isAuthenticated
}: {
  options?: {
    enabled?: boolean;
  };
  isAuthenticated: boolean;
}) => {
  if (!isAuthenticated) {
    return false;
  }

  return options?.enabled ?? true;
};

export function useGetMe(options?: {
  enabled?: boolean;
  onSuccess?: (data: UserMe) => void;
}) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthenticationStatus();

  return useQuery<UserMe, ErrorResponse>('users/me', getMe, {
    enabled: getEnabledStatus({ options, isAuthenticated: isAuthenticated() }),
    onSuccess: (data) => {
      if (options?.onSuccess) {
        options.onSuccess(data);
      }

      dispatch({
        type: types.GET_MY_PROFILE.success,
        payload: { user: data }
      });
    }
  });
}

export function useGetEditSuggestions() {
  return useQuery<EditSuggestions, ErrorResponse>(
    'users/me/editSuggestions',
    getEditSuggestions
  );
}

export function useEditMeCommand() {
  const dispatch = useDispatch();

  return useMutation<UserMe, ErrorResponse, EditUser>(editMe, {
    onSuccess: (data) => {
      dispatch({
        type: types.UPDATE_PROFILE.success,
        payload: data
      });
    }
  });
}

export function usePostOnboardingRequirements() {
  const dispatch = useDispatch();

  return useMutation<UserMe, ErrorResponse, OnboardingRequirements>(
    postOnboardingRequirements,
    {
      onSuccess: (data) => {
        dispatch({
          type: types.UPDATE_PROFILE.success,
          payload: data
        });
      }
    }
  );
}

export function useGetHostedEvents() {
  return useQuery<EventLean[], ErrorResponse>(
    'users/me/hostedEvents',
    getHostedEvents
  );
}

export function useGetRequiredEventRatings() {
  const { isAuthenticated } = useAuthenticationStatus();

  return useQuery<RequiredEventRatingsDto, ErrorResponse>(
    'users/me/requiredEventRatings',
    getRequiredEventRatings,
    {
      enabled: isAuthenticated()
    }
  );
}

export function useFriendRequestCommandsForGuestList({
  eventId,
  searchTerm,
  multitierQuery,
  isOrganizer
}: {
  eventId: string;
  searchTerm: string;
  multitierQuery?: boolean;
  isOrganizer?: boolean;
}) {
  const queryClient = useQueryClient();
  const dataKey = isOrganizer
    ? 'search/guestListBreakdown'
    : 'search/guestList';

  const handleMutate = async ({
    friendId,
    newStatus
  }: {
    friendId: string;
    newStatus: string;
  }) => {
    if (!multitierQuery) {
      await queryClient.cancelQueries(dataKey);
      const previousData = queryClient.getQueryData<any>([
        dataKey,
        eventId,
        searchTerm
      ]);

      const newPages = previousData.pages.map((page) =>
        page.map((oldResult) =>
          oldResult._id === friendId
            ? { ...oldResult, friendStatus: newStatus }
            : oldResult
        )
      );

      queryClient.setQueryData<any>([dataKey, eventId, searchTerm], {
        ...previousData,
        pages: newPages
      });

      return {
        previousData
      };
    }
    queryClient.refetchQueries([
      'search/multiTierGuestList',
      eventId,
      searchTerm
    ]);

    return null;
  };

  const handleError = (previousData: any) => {
    if (!previousData) {
      return;
    }

    if (!multitierQuery) {
      // Revert to the previous value in the cache
      queryClient.setQueryData<any>(
        [dataKey, eventId, searchTerm],
        previousData
      );
    } else {
      // Revert to the previous value in the cache
      queryClient.refetchQueries([
        'search/multiTierGuestList',
        eventId,
        searchTerm
      ]);
    }
  };

  const sendFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(sendFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestSent'
      })
  });

  const cancelFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(cancelFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestable'
      })
  });

  const acceptFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(acceptFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'friends'
      })
  });

  const declineFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(declineFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestable'
      })
  });

  const removeFriendCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(removeFriend, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestable'
      })
  });

  return {
    sendFriendRequestCommand,
    acceptFriendRequestCommand,
    cancelFriendRequestCommand,
    declineFriendRequestCommand,
    removeFriendCommand
  };
}

// Todo: Refactor this to make a common hook that supports the friend actions; while maintaining the optimistic updates
export function useFriendRequestCommandsForSuggestedFriends({ eventId }) {
  const queryClient = useQueryClient();

  const handleMutate = async ({
    friendId,
    newStatus
  }: {
    friendId: string;
    newStatus: string;
  }) => {
    await queryClient.cancelQueries('search/guestList');
    const previousData = queryClient.getQueryData<SuggestedFriendsFromEventDto>(
      ['events/suggestedFriends', eventId]
    );

    const newData = previousData.suggestedFriends.map((oldResult) =>
      oldResult.user.id === friendId
        ? {
            ...oldResult,
            user: {
              ...oldResult.user,
              friendStatus: newStatus
            }
          }
        : oldResult
    );

    queryClient.setQueryData<any>(['events/suggestedFriends', eventId], {
      eventId,
      suggestedFriends: newData
    });

    return {
      previousData
    };
  };

  const handleError = (previousData: any) => {
    if (!previousData) {
      return;
    }

    // Revert to the previous value in the cache
    queryClient.setQueryData<any>(
      ['events/suggestedFriends', eventId],
      previousData
    );
  };

  const sendFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(sendFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestSent'
      })
  });

  const cancelFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(cancelFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestable'
      })
  });

  const acceptFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(acceptFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'friends'
      })
  });

  const declineFriendRequestCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(declineFriendRequest, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestable'
      })
  });

  const removeFriendCommand = useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    string,
    any
  >(removeFriend, {
    onError: (_1, _2, context) => handleError(context.previousData),
    onMutate: (friendId) =>
      handleMutate({
        friendId,
        newStatus: 'requestable'
      })
  });

  return {
    sendFriendRequestCommand,
    acceptFriendRequestCommand,
    cancelFriendRequestCommand,
    declineFriendRequestCommand,
    removeFriendCommand
  };
}

export function useUploadProfilePictureCommand() {
  return useMutation<{ photo: string }, ErrorResponse, Blob>(
    uploadProfilePicture
  );
}

export function useUploadGalleryPhotoCommand() {
  return useMutation<
    { allPhotos: UserAllPhoto[]; photo: UserAllPhoto },
    ErrorResponse,
    Blob
  >(uploadGalleryPhoto);
}

export function useCreateEditPhoneVerificationSessionCommand() {
  return useMutation<
    VerificationSessionDto,
    ErrorResponse,
    {
      phone: string;
      countryCode: string;
    }
  >(createEditPhoneVerificationSession);
}

export function useConfirmEditPhoneVerificationSessionCommand() {
  return useMutation<
    UserMe,
    ErrorResponse,
    {
      verificationSessionId: string;
      smsCode: string;
    }
  >(confirmEditPhoneVerificationSession);
}

export function useUpdateEmailCommand() {
  return useMutation<
    UserMe,
    ErrorResponse,
    { email: string; password: string }
  >(updateEmail, {
    onSuccess: (data) => {
      queryClient.setQueryData('users/me', data);
    }
  });
}

export function useGetAuthMigrationRequirementsCommand() {
  return useMutation<MigrationRequirementsDto, ErrorResponse>(
    getAuthMigrationRequirements
  );
}

export function useGetCanTextAll() {
  return useQuery<CanTextAllDto, ErrorResponse>(
    ['users/canTextAll'],
    () => getCanTextAll(),
    { retry: 3 }
  );
}

export function useGetHostingRequirementsLink(eventId: string) {
  return useMutation<
    HostingRequirementsLinkResponse,
    ErrorResponse,
    HostingRequirementsLinkParams
  >(() => getHostingRequirementsLink(eventId));
}
