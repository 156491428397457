import { useState } from 'react';
import Cropper from 'react-easy-crop';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { Area, Point } from 'react-easy-crop/types';
import { getCroppedImage } from 'legacy/common/utils/image';

import Slider from '../slider/Slider';
import Button from '../button/Button';
import Modal, { ModalType } from '../modal/Modal';

type UploadPhotoModal = {
  preview: string;
  isLoading?: boolean;
  aspectRatio?: number;
  errorMessage?: string;
  cropShape?: 'round' | 'rect';
  children?: React.ReactNode;
  isUploadButtonDisabled?: boolean;
  onClose: () => void;
  uploadPhoto: (blob: Blob) => void;
};

const UploadPhotoModal = ({
  preview,
  isLoading,
  errorMessage,
  cropShape,
  aspectRatio,
  onClose,
  uploadPhoto,
  children,
  isUploadButtonDisabled
}) => {
  const [zoom, setZoom] = useState<number>(1.2);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [errorFromCropper, setErrorFromCropper] = useState();

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (_, cap) => {
    setCroppedAreaPixels(cap);
  };

  const handleSaveChanges = async (croppedAreaPixels: Area) => {
    try {
      const cropped = await getCroppedImage(preview, croppedAreaPixels);

      await uploadPhoto(cropped);

      URL.revokeObjectURL(preview);
      onClose();
    } catch (err) {
      setErrorFromCropper(err.message);
    }
  };

  return (
    <Modal
      visible
      type={ModalType.VERY_SMALL}
      onClose={onClose}
      minHeight="500px"
    >
      <div className="py-12 px-10 h-full overflow-hidden">
        <div className="w-full aspect-4/5 mx-auto relative rounded-xl overflow-hidden">
          <Cropper
            aspect={aspectRatio || 1}
            crop={crop}
            zoom={zoom}
            image={preview}
            cropShape={cropShape || 'round'}
            showGrid={false}
            restrictPosition
            onCropChange={setCrop}
            onZoomChange={setZoom}
            objectFit="contain"
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="mt-6 text-caption text-gray-500 flex justify-center">
          Drag to reposition image
        </div>
        <div className="mt-6 flex items-center space-x-5">
          <FiZoomOut size="20" />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.01}
            onChange={(evt) => setZoom(Number(evt.target.value))}
          />
          <FiZoomIn size="20" />
        </div>

        {children}

        <div className="mt-10">
          {errorMessage ||
            (errorFromCropper && (
              <div className="mb-2 text-center text-red-500">
                {errorMessage || errorFromCropper}
              </div>
            ))}
          <Button
            disabled={isUploadButtonDisabled}
            loading={isLoading}
            onClick={() => handleSaveChanges(croppedAreaPixels)}
          >
            Upload
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadPhotoModal;
