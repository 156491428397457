import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { resolveError } from './actionUtil';
import { toActionObject } from '../util/util';
import { queryClient } from '../common/utils/react-query';

export const actionIDs = [
  'GET_FRIENDS',
  'REMOVE_FRIEND',
  'SEND_FRIEND_REQUEST',
  'CANCEL_FRIEND_REQUEST',
  'GET_RECEIVED_FRIEND_REQUESTS',
  'GET_SENT_FRIEND_REQUESTS',
  'ACCEPT_FRIEND_REQUEST',
  'DECLINE_FRIEND_REQUEST',
  'INVITE_FRIENDS',
  'INVITE_FRIENDS_FORM'
];

export const types = toActionObject(actionIDs);

export function getFriends() {
  return function (dispatch) {
    dispatch({ type: types.GET_FRIENDS.start });
    bounceApiClient
      .get('/api/friends')
      .then((res) => {
        dispatch({
          type: types.GET_FRIENDS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_FRIENDS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function removeFriend(friendId) {
  return function (dispatch) {
    dispatch({ type: types.REMOVE_FRIEND.start });
    bounceApiClient
      .delete(`/api/friends/${friendId}`)
      .then((res) => {
        queryClient.invalidateQueries(['search/multiTierGuestList']);
        dispatch({
          type: types.REMOVE_FRIEND.success,
          payload: {
            _id: friendId,
            ...res.data
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.REMOVE_FRIEND.error,
          payload: resolveError(err)
        });
      });
  };
}

export function sendFriendRequest(user) {
  return function (dispatch) {
    dispatch({ type: types.SEND_FRIEND_REQUEST.start });
    bounceApiClient
      .post(`/api/friends`, { receiver: user._id })
      .then((res) => {
        queryClient.invalidateQueries(['search/multiTierGuestList']);
        dispatch({
          type: types.SEND_FRIEND_REQUEST.success,
          payload: {
            user,
            ...res.data
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SEND_FRIEND_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

export function cancelFriendRequest(user) {
  return function (dispatch) {
    dispatch({ type: types.CANCEL_FRIEND_REQUEST.start });
    bounceApiClient
      .post(`/api/friends/requests/cancel`, { receiver: user._id })
      .then((res) => {
        queryClient.invalidateQueries(['search/multiTierGuestList']);
        dispatch({
          type: types.CANCEL_FRIEND_REQUEST.success,
          payload: {
            user,
            ...res.data
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SEND_FRIEND_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getReceivedFriendRequests() {
  return function (dispatch) {
    bounceApiClient
      .get(`/api/friends/requests/received`)
      .then((res) => {
        dispatch({
          type: types.GET_RECEIVED_FRIEND_REQUESTS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_RECEIVED_FRIEND_REQUESTS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getSentFriendRequests() {
  return function (dispatch) {
    bounceApiClient
      .get(`/api/friends/requests/sent`)
      .then((res) => {
        dispatch({
          type: types.GET_SENT_FRIEND_REQUESTS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_SENT_FRIEND_REQUESTS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function acceptFriendRequest(user) {
  return function (dispatch) {
    dispatch({ type: types.ACCEPT_FRIEND_REQUEST.start });
    bounceApiClient
      .post(`/api/friends/requests/accept`, { sender: user._id })
      .then((res) => {
        queryClient.invalidateQueries(['search/multiTierGuestList']);
        dispatch({
          type: types.ACCEPT_FRIEND_REQUEST.success,
          payload: {
            requests: res.data,
            user
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.ACCEPT_FRIEND_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

export function declineFriendRequest(user) {
  return function (dispatch) {
    dispatch({ type: types.DECLINE_FRIEND_REQUEST.start });
    bounceApiClient
      .post(`/api/friends/requests/decline`, { sender: user._id })
      .then((res) => {
        queryClient.invalidateQueries(['search/multiTierGuestList']);
        dispatch({
          type: types.DECLINE_FRIEND_REQUEST.success,
          payload: {
            requests: res.data,
            _id: user._id
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.DECLINE_FRIEND_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

export function inviteFriends(emails, numbers) {
  return function (dispatch) {
    dispatch({ type: types.INVITE_FRIENDS.start });
    bounceApiClient
      .post('/api/friends/invite', { emails, numbers })
      .then((res) => {
        queryClient.invalidateQueries(['search/multiTierGuestList']);
        dispatch({
          type: types.INVITE_FRIENDS.success,
          payload: res.data
        });
        dispatch({ type: types.INVITE_FRIENDS_FORM.success });
      })
      .catch((err) => {
        const { error } = resolveError(err);
        dispatch({
          type: types.INVITE_FRIENDS_FORM.success,
          payload: { FORM_ERROR: error.message }
        });
        dispatch({
          type: types.INVITE_FRIENDS.error,
          payload: { error }
        });
      });
  };
}
