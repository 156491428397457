import * as Sentry from '@sentry/nextjs';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import {
  CheckoutSession,
  ContactHostOptions,
  ConfirmCheckoutSessionParams,
  UpdateCheckoutSessionParams,
  UpdateUnauthenticatedCheckoutSessionParams,
  EventSummary,
  VerifyTicketResultDto,
  SubmitEventRatingBody,
  SuggestedFriendsFromEventDto,
  EventForUser,
  TicketInfoRequiredResponse,
  AnalyticsDto,
  DiscountCodeType,
  DiscountCodeDto,
  EventDiscountCodesDto,
  UpdateDiscountCodeParams,
  DeleteDiscountCodeParams,
  DiscountCodeAnalyticsDto,
  CreateDiscountCodeParams,
  TextAllGuestsParams,
  TextAllGuestsDto,
  PutPayoutDestinationParams,
  EventWaiverDto,
  PostEarlyPayoutParams,
  UploadPhotoResponseDto,
  EventEdit,
  EventCreation,
  TierApprovals,
  TierApprovalAnswer,
  TierApproval,
  MultiTierCheckoutSession,
  UpdateUnauthenticatedMultiTierCheckoutSessionParams,
  UpdateMultiTierCheckoutSessionParams,
  RequestApprovalParams,
  CreateWaiverDto,
  UpdateWaiverDto,
  WaiverDto,
  GuestEntryList,
  GuestEntryDto,
  TierDto,
  CreateTierDto,
  UpdateTierDto,
  MomentDto,
  CreateMoment,
  UploadVideoResponseDto,
  CreateUploadVideoUrlResponseDto,
  EventAnalytics,
  RequestToPublishFormDto,
  SubmitRequestToPublishFormDto,
  RecurringEventDto,
  RecurringFrequency,
  OfferType,
  CreateOfferParams,
  PromoterOfferDto,
  PromoterOffer,
  TierReorderDto,
  SectionDto,
  GetEventTiersDto,
  SectionReorderDto
} from './types';

import { ErrorResponse } from 'legacy/common/utils/error';

import {
  confirmCheckoutSession,
  contactHost,
  inviteUsersToEvent,
  InviteUsersToEventOptions,
  startCheckoutSession,
  startUnauthenticatedCheckoutSession,
  updateCheckoutSession,
  updateUnauthenticatedCheckoutSession,
  inviteGuestsToEvent,
  refundTicket,
  refundMultiTickets,
  getEventSummary,
  exportEventGuests,
  RefundTicketOptions,
  deleteEvent,
  verifyTicket,
  submitEventRating,
  getSuggestedFriendsFromEvent,
  getGuestTicket,
  partiallyRefundTicket,
  fullyRefundTicket,
  getEvent,
  uploadGalleryPhoto,
  deleteGalleryPhoto,
  deleteCoverPhoto,
  validateEventUrl,
  getTicketInfoRequired,
  publicGetEvent,
  createTrackingLink,
  getAnalytics,
  getDiscountCodes,
  exportAnalytics,
  inviteGroupToEvent,
  updateDiscountCode,
  deleteDiscountCode,
  publicVerifyCode,
  publicDiscountCodeByCode,
  publicGetTierFromShareToken,
  getDiscountCodesAnalytics,
  createDiscountCode,
  textAllGuests,
  putPayoutDestination,
  getEventWaiver,
  createEvent,
  editEvent,
  uploadPhoto,
  postEarlyPayout,
  createComplimentaryTickets,
  getComplimentaryTickets,
  getRequestApprovals,
  updateRequestApprovalMutation,
  exportRequestApprovalList,
  getRequestApproval,
  startCheckoutSessionV2,
  updateCheckoutSessionV2,
  confirmCheckoutSessionV2,
  startUnauthenticatedCheckoutSessionV2,
  updateUnauthenticatedCheckoutSessionV2,
  requestTicketApproval,
  createEventWaiver,
  updateEventWaiver,
  deleteEventWaiver,
  getEventEntryGuests,
  getEventEntryDetail,
  admitGuest,
  getEventTier,
  createEventTier,
  deleteEventTier,
  updateEventTier,
  getEventTiers,
  createMoments,
  deleteOrganization,
  uploadHypeVideo,
  deleteHypeVideo,
  createUploadVideoUrl,
  getEventAnalytics,
  requestToPublishEvent,
  getRequestToPublishForm,
  submitRequestToPublishForm,
  getRequestToPublishResponse,
  createEventSeries,
  getEventRecurringSeries,
  updateEventSeries,
  requestEarlyPayout,
  createOffer,
  getOffers,
  getOffer,
  getMyEligibleOffers,
  reorderTiers,
  toggleTierVisibility,
  createSection,
  updateSection,
  deleteSection
} from './service';
import { GenericSuccessResponse } from 'legacy/common/types/response';
import { TicketDto } from 'legacy/tickets/types';
import { getLoggedInUserId } from 'legacy/user/service';
import { queryClient } from 'legacy/common/utils/react-query';
import useAuthenticationStatus from 'legacy/common/hooks/useAuthenticationStatus';
import { Event } from 'legacy/common/types/models';

export function useGetEvent(eventId: string, options?: { shareToken: string }) {
  return useQuery<EventForUser, ErrorResponse>(
    ['events', eventId],
    () => {
      if (getLoggedInUserId()) {
        return getEvent(eventId);
      }

      return publicGetEvent(eventId, options);
    },
    {
      enabled: !!eventId
    }
  );
}

export function useGetRequestToPublishForm(formId: string) {
  return useQuery<RequestToPublishFormDto, ErrorResponse>(
    ['publication-form', formId],
    () => getRequestToPublishForm(formId),
    {
      enabled: !!formId
    }
  );
}

export function useGetRequestToPublishResponse(responseId: string) {
  return useQuery<SubmitRequestToPublishFormDto, ErrorResponse>(
    ['publication-response', responseId],
    () => getRequestToPublishResponse(responseId),
    {
      enabled: !!responseId
    }
  );
}

export function useGetEventTiers(eventId: string) {
  return useQuery<GetEventTiersDto, ErrorResponse>(
    ['events/tiers', eventId],
    () => getEventTiers({ eventId }),
    {
      enabled: !!eventId
    }
  );
}

export function useCreateEventTier(eventId: string) {
  return useMutation<TierDto, ErrorResponse, CreateTierDto>((tierData) =>
    createEventTier(eventId, tierData)
  );
}

export function useDeleteEventTier(eventId: string) {
  return useMutation<GenericSuccessResponse, ErrorResponse, { tierId: string }>(
    ({ tierId }) => deleteEventTier(eventId, tierId)
  );
}

export function useUpdateEventTier(eventId: string) {
  return useMutation<
    TierDto,
    ErrorResponse,
    { tierId: string; tierData: UpdateTierDto }
  >(({ tierId, tierData }) => updateEventTier(eventId, tierId, tierData), {
    onSuccess: () => {
      queryClient.invalidateQueries(['events/tiers', eventId]);
    }
  });
}

export function useGetEventTier(eventId: string, tierId: string) {
  return useQuery<TierDto, ErrorResponse>(
    ['events/tier', eventId, tierId],
    () => getEventTier(eventId, tierId)
  );
}

export function useGetEventWaiver({ eventId }: { eventId: string }) {
  return useQuery<EventWaiverDto, ErrorResponse>(
    ['events/waiver', eventId],
    () => getEventWaiver({ eventId }),
    {
      enabled: !!eventId
    }
  );
}

export function useCreateEventWaiver(eventId: string) {
  return useMutation<WaiverDto, ErrorResponse, CreateWaiverDto>((waiverData) =>
    createEventWaiver(eventId, waiverData)
  );
}

export function useUpdateEventWaiver(eventId: string) {
  return useMutation<
    WaiverDto,
    ErrorResponse,
    { waiverId: string; waiverData: UpdateWaiverDto }
  >(({ waiverId, waiverData }) =>
    updateEventWaiver(eventId, waiverId, waiverData)
  );
}

export function useDeleteEventWaiver(eventId: string) {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { waiverId: string }
  >(({ waiverId }) => deleteEventWaiver(eventId, waiverId));
}

export function useContactHostMutation() {
  return useMutation<
    Record<string, unknown>,
    ErrorResponse,
    ContactHostOptions
  >(contactHost);
}

export function useInviteUsersToEventMutation() {
  return useMutation<
    Record<string, unknown>,
    ErrorResponse,
    InviteUsersToEventOptions
  >(inviteUsersToEvent);
}

export function useStartCheckoutSession() {
  return useMutation<CheckoutSession, ErrorResponse, string>(
    startCheckoutSession
  );
}

export function useStartMultiTierCheckoutSession() {
  return useMutation<MultiTierCheckoutSession, ErrorResponse, string>(
    startCheckoutSessionV2
  );
}

export function useStartUnauthenticatedCheckoutSession() {
  return useMutation<CheckoutSession, ErrorResponse, string>(
    startUnauthenticatedCheckoutSession
  );
}

export function useStartUnauthenticatedMultiTierCheckoutSession() {
  return useMutation<MultiTierCheckoutSession, ErrorResponse, string>(
    startUnauthenticatedCheckoutSessionV2
  );
}

export function useUpdateCheckoutSession() {
  return useMutation<
    CheckoutSession,
    ErrorResponse,
    UpdateCheckoutSessionParams
  >(updateCheckoutSession);
}

export function useUpdateMultiTierCheckoutSession() {
  return useMutation<
    MultiTierCheckoutSession,
    ErrorResponse,
    UpdateMultiTierCheckoutSessionParams
  >(updateCheckoutSessionV2);
}

export function useUpdateUnauthenticatedCheckoutSession() {
  return useMutation<
    CheckoutSession,
    ErrorResponse,
    UpdateUnauthenticatedCheckoutSessionParams
  >(updateUnauthenticatedCheckoutSession);
}

export function useUpdateUnauthenticatedMultiTierCheckoutSession() {
  return useMutation<
    MultiTierCheckoutSession,
    ErrorResponse,
    UpdateUnauthenticatedMultiTierCheckoutSessionParams
  >(updateUnauthenticatedCheckoutSessionV2);
}

export function useConfirmCheckoutSession() {
  return useMutation<void, ErrorResponse, ConfirmCheckoutSessionParams>(
    confirmCheckoutSession
  );
}

export function useConfirmMultiTierCheckoutSession() {
  return useMutation<void, ErrorResponse, ConfirmCheckoutSessionParams>(
    confirmCheckoutSessionV2
  );
}

export function useRequestTierApproval() {
  return useMutation<void, ErrorResponse, RequestApprovalParams>(
    requestTicketApproval
  );
}

export function useInviteGuestsFromHostedEventMutation() {
  return useMutation<
    { success: boolean },
    ErrorResponse,
    {
      eventId: string;
      sourceEventId: string;
    }
  >(inviteGuestsToEvent);
}

export function useInviteGroupToEventMutation() {
  return useMutation<
    { success: boolean },
    ErrorResponse,
    {
      eventId: string;
      groupId: string;
    }
  >(inviteGroupToEvent);
}

export function useRefundTicketMutation() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    RefundTicketOptions
  >(refundTicket, {
    mutationKey: '/refundTicket'
  });
}

export function useRefundMultiTicketCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    RefundTicketOptions
  >(refundMultiTickets);
}

export function useGetEventSummary(eventId: string, isOrganizer: boolean) {
  return useQuery<EventSummary, ErrorResponse>(
    ['events/summary', eventId],
    () => getEventSummary(eventId),
    {
      enabled: isOrganizer === true
    }
  );
}

export function useGetEventAnalytics(eventId: string, isOrganizer: boolean) {
  return useQuery<EventAnalytics, ErrorResponse>(
    ['events/analytics/v2', eventId],
    () => getEventAnalytics(eventId),
    {
      enabled: isOrganizer === true
    }
  );
}

export function useExportEventGuests(
  eventId: string,
  { isEnabled }: { isEnabled: boolean }
) {
  return useQuery<Blob, ErrorResponse>(
    ['events/exportGuests', eventId],
    () => exportEventGuests(eventId),
    { enabled: isEnabled }
  );
}

export function useExportEventAnalytics(
  eventId: string,
  { isEnabled }: { isEnabled: boolean }
) {
  return useQuery<Blob, ErrorResponse>(
    ['events/exportAnalytics', eventId],
    () => exportAnalytics(eventId),
    { enabled: isEnabled }
  );
}

export function useDeleteEvent() {
  return useMutation<void, ErrorResponse, string>(deleteEvent);
}

export function useDeleteOrganization() {
  return useMutation<void, ErrorResponse, string>(deleteOrganization);
}

export function useGetTierFromShareTokenCommand() {
  return useMutation<
    { tierId: string; tierName: string },
    ErrorResponse,
    {
      eventId: string;
      shareToken: string;
    }
  >(publicGetTierFromShareToken);
}

export function getVerifyCode(eventId, code, numberOfTickets, options = {}) {
  return useQuery<DiscountCodeType, ErrorResponse>(
    ['events/verifyCode', eventId, code, numberOfTickets],
    () => publicVerifyCode(eventId, code, numberOfTickets),
    { enabled: code?.length > 0, retry: 0, ...options }
  );
}

export function getDiscountCodeByCode(eventId, code) {
  return useQuery<DiscountCodeDto, ErrorResponse>(
    ['events', eventId, code],
    () => publicDiscountCodeByCode(eventId, code),
    {
      enabled: code?.length > 0,
      retry: 0,
      staleTime: Infinity
    }
  );
}

export function useVerifyTicketCommand() {
  return useMutation<
    VerifyTicketResultDto,
    ErrorResponse,
    {
      eventId: string;
      qrCodeString: string;
    }
  >(verifyTicket);
}

export function useSubmitEventRatingCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { eventId: string; body: SubmitEventRatingBody }
  >(submitEventRating, {
    onError: (error) => {
      // Silenty capture error and send to Sentry without disrupting user experience
      Sentry.captureMessage(
        `Encountered an unexpected network error from submiting Event Rating: ${error.message}`,
        { level: 'warning' }
      );
    }
  });
}

export function useGetSuggestedFriendsFromEvent(eventId: string) {
  const { isAuthenticated } = useAuthenticationStatus();

  return useQuery<SuggestedFriendsFromEventDto, ErrorResponse>(
    ['events/suggestedFriends', eventId],
    () => getSuggestedFriendsFromEvent(eventId),
    {
      enabled: isAuthenticated()
    }
  );
}

export function useGetGuestTicket({
  eventId,
  ticketId
}: {
  eventId: string;
  ticketId: string;
}) {
  return useQuery<TicketDto, ErrorResponse>(
    ['tickets/guest', eventId, ticketId],
    () => getGuestTicket({ eventId, ticketId })
  );
}

export function useGetEventEntryGuestDetail({
  eventId,
  ticketId
}: {
  eventId: string;
  ticketId: string;
}) {
  return useQuery<GuestEntryDto, ErrorResponse>(
    ['tickets/guest/details', eventId, ticketId],
    () => getEventEntryDetail({ eventId, ticketId })
  );
}

export function usePartiallyRefundTicketCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    {
      eventId: string;
      claimerId?: string;
      transactionId: string;
    }
  >(partiallyRefundTicket);
}

export function useFullyRefundTicketCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    {
      eventId: string;
      transactionIds: {
        purchaserTransactionId: string;
        subsequentTransactionIds: string[];
      };
    }
  >(fullyRefundTicket);
}

export function useUploadGalleryPhotoCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { eventId: string; blob: Blob; caption: string }
  >(uploadGalleryPhoto);
}

export function useDeleteGalleryPhotoCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { eventId: string; photoId: string }
  >(deleteGalleryPhoto);
}

export function useUploadPhotoCommand() {
  return useMutation<
    UploadPhotoResponseDto,
    ErrorResponse,
    { eventId: string; blob: Blob }
  >(uploadPhoto);
}

export function useDeleteCoverPhotoCommand() {
  return useMutation<GenericSuccessResponse, ErrorResponse, string>(
    deleteCoverPhoto
  );
}

export function useUploadHypeVideoCommand() {
  return useMutation<
    UploadVideoResponseDto,
    ErrorResponse,
    { eventId: string; videoId: string }
  >(uploadHypeVideo);
}

export function useCreateUploadVideoUrlCommand() {
  return useMutation<
    CreateUploadVideoUrlResponseDto,
    ErrorResponse,
    { uploadLength: string; uploadMetadata: string }
  >(createUploadVideoUrl);
}

export function useDeleteHypeVideoCommand() {
  return useMutation<GenericSuccessResponse, ErrorResponse, string>(
    deleteHypeVideo
  );
}

export function useValidateEventUrlCommand() {
  return useMutation<
    EventForUser,
    ErrorResponse,
    { eventId: string; shareToken: string }
  >(validateEventUrl);
}

export function useGetTicketInfoRequired(eventId: string) {
  const { isAuthenticated } = useAuthenticationStatus();

  return useQuery<TicketInfoRequiredResponse, ErrorResponse>(
    ['events/ticketInfoRequired', eventId],
    () => getTicketInfoRequired(eventId),
    {
      enabled: isAuthenticated()
    }
  );
}

export function useGetMyEligibleOffers(eventId: string) {
  return useQuery(
    ['events/tickets/eligibleOffers', eventId],
    () => getMyEligibleOffers(eventId),
    {
      enabled: !!eventId
    }
  );
}

export function useCreateTrackingLinkCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { eventId: string; name: string }
  >(createTrackingLink, {
    onSuccess: (_, { eventId }) =>
      queryClient.invalidateQueries(['events/analytics', eventId])
  });
}

export function useGetDiscountCodes(
  eventId: string,
  options: { enabled: boolean }
) {
  return useQuery<EventDiscountCodesDto, ErrorResponse>(
    ['events/discountCodes', eventId],
    () => getDiscountCodes(eventId),
    {
      enabled: options.enabled === true
    }
  );
}

export function useCreateDiscountCode() {
  return useMutation<DiscountCodeDto, ErrorResponse, CreateDiscountCodeParams>(
    createDiscountCode
  );
}

export function useUpdateDiscountCode() {
  return useMutation<DiscountCodeDto, ErrorResponse, UpdateDiscountCodeParams>(
    updateDiscountCode
  );
}

export function useDeleteDiscountCode() {
  return useMutation<void, ErrorResponse, DeleteDiscountCodeParams>(
    deleteDiscountCode
  );
}

export function useCreateOffer() {
  return useMutation<OfferType, ErrorResponse, CreateOfferParams>(createOffer);
}

export function useGetOffers(eventId: string, options: { enabled: boolean }) {
  return useQuery<{ offers: PromoterOfferDto[] }, ErrorResponse>(
    ['events/offers', eventId],
    () => getOffers(eventId),
    {
      enabled: options.enabled === true
    }
  );
}

export function useGetOffer(offerId: string, options: { enabled: boolean }) {
  return useQuery<PromoterOffer, ErrorResponse>(
    ['events/offer', offerId],
    () => getOffer(offerId),
    {
      enabled: options.enabled === true
    }
  );
}

export function useGetAnalytics(
  eventId: string,
  options: { enabled: boolean }
) {
  return useQuery<AnalyticsDto, ErrorResponse>(
    ['events/analytics', eventId],
    () => getAnalytics(eventId),
    {
      enabled: options.enabled === true
    }
  );
}

export function useGetDiscountCodesAnalytics(
  eventId: string,
  options: { enabled: boolean }
) {
  return useQuery<DiscountCodeAnalyticsDto[], ErrorResponse>(
    ['events/analytics/discountCodes', eventId],
    () => getDiscountCodesAnalytics(eventId),
    {
      enabled: options.enabled === true
    }
  );
}

export function useExportAnalyticsCommand(eventId: string) {
  return useQuery<Blob, ErrorResponse>(
    ['events/exportAnalytics', eventId],
    () => exportAnalytics(eventId)
  );
}

export function useTextAllGuests() {
  return useMutation<TextAllGuestsDto, ErrorResponse, TextAllGuestsParams>(
    textAllGuests
  );
}

export function usePutPayoutDestination() {
  return useMutation<void, ErrorResponse, PutPayoutDestinationParams>(
    putPayoutDestination
  );
}

export function useCreateEvent() {
  return useMutation<
    Event,
    ErrorResponse,
    {
      event: EventCreation;
    }
  >(createEvent);
}

export function useEditEvent() {
  return useMutation<
    Event,
    ErrorResponse,
    {
      eventId: string;
      event: EventEdit;
    }
  >(editEvent);
}

export function useRequestToPublishEvent() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    {
      eventId: string;
      eventSeriesId?: string;
      responseId?: string;
    }
  >(requestToPublishEvent);
}

export function useSubmitRequestToPublishForm() {
  return useMutation<
    SubmitRequestToPublishFormDto,
    ErrorResponse,
    {
      formId: string;
      eventSeriesId?: string;
      body: { [key: string]: unknown };
    }
  >(submitRequestToPublishForm);
}

export function usePostEarlyPayout() {
  return useMutation<void, ErrorResponse, PostEarlyPayoutParams>(
    postEarlyPayout
  );
}

export function useRequestEarlyPayout() {
  return useMutation<void, ErrorResponse, PostEarlyPayoutParams>(
    requestEarlyPayout
  );
}

export function useCreateComplimentaryTickets() {
  return useMutation(createComplimentaryTickets);
}

export function useGetComplimentaryTickets(eventId: string) {
  return useQuery(
    [`api/events/tickets/complimentary`, eventId],
    () => getComplimentaryTickets(eventId),
    {
      enabled: !!eventId
    }
  );
}

export function useGetRequestApprovals(options?: {
  eventId: string;
  limit?: number;
}) {
  return useInfiniteQuery<TierApprovals, ErrorResponse>(
    ['requestApprovals', options.eventId],
    ({ pageParam }) =>
      getRequestApprovals({
        eventId: options.eventId,
        limit: options?.limit,
        pageNumber: pageParam
      })
  );
}

export function useUpdateRequestApprovalMutation() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    {
      eventId: string;
      approvalId: string;
      status?: 'pending' | 'approved' | 'rejected';
      readStatus?: 'read' | 'unread';
      answers?: TierApprovalAnswer[];
    }
  >(updateRequestApprovalMutation, {
    onMutate: async (variables) => {
      // Cancelling outgoing refetches so it doesn't override the optimistic update.
      queryClient.cancelQueries(['requestApprovals', variables.eventId]);
      queryClient.cancelQueries([
        'requestApproval',
        variables.eventId,
        variables.approvalId
      ]);
      queryClient.cancelQueries(['events', variables.eventId]);

      const data = queryClient.getQueryData([
        'requestApprovals',
        variables.eventId
      ]);

      if (data) {
        queryClient.setQueryData(
          ['requestApprovals', variables.eventId],
          (oldData: TierApprovals) => ({
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              items: page.items.map((item) => {
                if (item.id === variables.approvalId) {
                  return {
                    ...item,
                    status: variables.status,
                    readStatus: variables.readStatus
                  };
                }

                return item;
              })
            }))
          })
        );
      }
    },
    onSettled: (_response, _error, variables) => {
      queryClient.invalidateQueries(['requestApprovals', variables.eventId]);
      queryClient.invalidateQueries([
        'requestApproval',
        variables.eventId,
        variables.approvalId
      ]);
      queryClient.invalidateQueries(['events', variables.eventId]);
    }
  });
}

export function useExportRequestApprovalList(
  eventId: string,
  { isEnabled }: { isEnabled: boolean }
) {
  return useQuery<Blob, ErrorResponse>(
    ['events/exportApprovalList', eventId],
    () => exportRequestApprovalList(eventId),
    { enabled: isEnabled }
  );
}

export function useGetRequestApproval({
  eventId,
  approvalId
}: {
  eventId: string;
  approvalId: string;
}) {
  return useQuery<TierApproval, ErrorResponse>(
    ['requestApproval', eventId, approvalId],
    () => getRequestApproval({ eventId, approvalId })
  );
}

export function useGetEventEntryGuests(options?: {
  eventId: string;
  limit?: number;
}) {
  return useInfiniteQuery<GuestEntryList, ErrorResponse>(
    ['eventEntryGuests', options.eventId],
    ({ pageParam }) =>
      getEventEntryGuests({
        eventId: options.eventId,
        limit: options?.limit,
        pageNumber: pageParam
      })
  );
}

export function useGetEventEntryDetail({
  eventId,
  ticketId
}: {
  eventId: string;
  ticketId: string;
}) {
  return useQuery<GuestEntryDto, ErrorResponse>(
    ['eventEntryDetail', eventId, ticketId],
    () => getEventEntryDetail({ eventId, ticketId })
  );
}

export function useAdmitGuestMutation() {
  return useMutation(admitGuest, {
    onSettled: (_data, _error, vars) => {
      queryClient.invalidateQueries([
        'eventEntryDetail',
        vars.eventId,
        vars.ticketId
      ]);
      queryClient.invalidateQueries(['eventEntryGuests', vars.eventId]);
    }
  });
}

export function useCreateMoments() {
  return useMutation<
    MomentDto[],
    ErrorResponse,
    { albumId: string; moments: CreateMoment[] }
  >(({ albumId, moments }) => createMoments({ albumId, moments }));
}

export function useCreateRecurringSeries() {
  return useMutation<
    RecurringEventDto,
    ErrorResponse,
    {
      frequency: RecurringFrequency;
      startDate: string;
      seriesEndDate: string;
      eventId: string;
    }
  >(
    ({ frequency, startDate, seriesEndDate, eventId }) =>
      createEventSeries({ frequency, startDate, seriesEndDate, eventId }),
    {
      onSettled: (_data, _error, vars) => {
        queryClient.invalidateQueries(['events', vars.eventId]);
        queryClient.removeQueries(['eventRecurringSeries']);
      }
    }
  );
}

export function useUpdateRecurringSeries() {
  return useMutation<
    RecurringEventDto,
    ErrorResponse,
    {
      frequency: RecurringFrequency;
      startDate: string;
      seriesEndDate: string;
      eventId: string;
    }
  >(
    ({ frequency, startDate, seriesEndDate, eventId }) =>
      updateEventSeries({ frequency, startDate, seriesEndDate, eventId }),
    {
      onSettled: (_data, _error, vars) => {
        queryClient.invalidateQueries(['events', vars.eventId]);
        queryClient.removeQueries(['eventRecurringSeries']);
      }
    }
  );
}

export function useGetEventRecurringSeries(
  { eventId }: { eventId: string },
  options
) {
  return useQuery<RecurringEventDto, ErrorResponse>(
    ['eventRecurringSeries', eventId],
    () => getEventRecurringSeries({ eventId }),
    { ...options, retry: false }
  );
}

export function useReorderTiersMutation() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { eventId: string; tiers: TierReorderDto[]; sections: SectionReorderDto[] }
  >(reorderTiers);
}

export function useToggleHideTier() {
  return useMutation<
    { isVisible: boolean },
    ErrorResponse,
    { eventId: string; tierId: string }
  >(({ eventId, tierId }) => toggleTierVisibility(eventId, tierId), {
    onSettled: (_data, _error, vars) => {
      queryClient.invalidateQueries(['events', vars.eventId]);
      queryClient.invalidateQueries(['events/tiers', vars.eventId]);
    }
  });
}

export function useCreateSectionMutation() {
  return useMutation<
    SectionDto,
    ErrorResponse,
    { eventId: string; name: string }
  >(createSection, {
    onSuccess: async (_, vars) => {
      await queryClient.invalidateQueries(['events/tiers', vars.eventId]);
    }
  });
}

export function useUpdateSectionMutation() {
  return useMutation<
    SectionDto,
    ErrorResponse,
    { eventId: string; sectionId: string; name: string }
  >(updateSection, {
    onSuccess: async (_, vars) => {
      await queryClient.invalidateQueries(['events/tiers', vars.eventId]);
    }
  });
}

export function useDeleteSectionMutation() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    { eventId: string; sectionId: string }
  >(deleteSection, {
    onSuccess: async (_, vars) => {
      await queryClient.invalidateQueries(['events/tiers', vars.eventId]);
    }
  });
}
